h1 {
  text-transform: uppercase;
}

.Admin {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 1200px;
  margin: 0 auto;
}

